import React, { useState, Suspense } from 'react';
import PropTypes from 'prop-types';
import {
  params, string, shape, bool, useDataModel
} from '@thd-nucleus/data-sources';
import './disclaimer-message.scss';

const Overlay = React.lazy(() => import(/* webpackChunkName: "overlay" */ '@thd-olt-component-react/overlay')
  .then((module) => ({ default: module.Overlay })));

// eslint-disable-next-line max-len
const DisclaimerMessageContent = React.lazy(() => import(/* webpackChunkName: "disclaimer-message-content" */ './DisclaimerMessageContent')
  .then((module) => ({ default: module.DisclaimerMessageContent })));

const DisclaimerMessage = ({ itemId }) => {
  const [open, setOpen] = useState(false);
  const { data, loading, error } = useDataModel('product', { variables: { itemId } });

  if (!data || loading || (error && !data)) {
    return null;
  }

  const {
    info = {}
  } = data?.product || {};

  if (!info?.forProfessionalUseOnly) {
    return null;
  }
  const openLegalDisclaimer = () => {
    setOpen(true);
  };
  return (
    <div
      className="disclaimer-message__wrapper disclaimer-message__text disclaimer-message__color"
      data-component="DisclaimerMessage"
    >
      <div className="alert-inline alert-inline--warning">
        <span className="alert-inline__message">
          <span className="alert-inline__title">
            <strong>
              This product is for professional and commercial use only. <br />
            </strong>
          </span>
          {'Please review our '}
          <span
            className="u__default-link"
            tabIndex={0}
            role="link"
            onClick={openLegalDisclaimer}
          >
            legal disclaimer
          </span>
          {open && (
            <Suspense fallback={(<div />)}>
              <Overlay
                closeButton
                open={open}
                medium
                onClose={() => setOpen(false)}
                header="Legal Disclaimer"
              >
                <Suspense fallback={(<div />)}>
                  <DisclaimerMessageContent />
                </Suspense>
              </Overlay>
            </Suspense>
          )}
          {' before purchasing this product.'}<br />
        </span>
      </div>
    </div>
  );
};

DisclaimerMessage.displayName = 'DisclaimerMessage';

DisclaimerMessage.dataModel = {
  product: params({ itemId: string().isRequired(), dataSource: string() }).shape({
    itemId: string(),
    info: shape({
      forProfessionalUseOnly: bool()
    })
  })
};

DisclaimerMessage.propTypes = {
  itemId: PropTypes.string
};

DisclaimerMessage.defaultProps = {
  itemId: null
};

export { DisclaimerMessage };
